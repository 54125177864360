const pt = {
    i: {
        datepicker: {
            weeks: {
                sun: "Dom",
                mon: "Seg",
                tue: "Ter",
                wed: "Qua",
                thu: "Qui",
                fri: "Sex",
                sat: "Sáb",
            },
        },
    },
    pageTitles: {
        accessGrants: "Concess\u00F5es de acesso",
        accountPreferences: "Preferências de conta",
        accounts: "Segmente suas contas",
        accountSegments: "Segmentos de Conta",
        activitiesFollowing: "Planner - Seguindo",
        activitiesToday: "Planner - Hoje",
        activitiesOverdue: "Planner - Atrasado",
        activitiesUpcoming: "Planner - Próximas",
        activitiesCompleted: "Planner - Concluídas",
        activitiesGrid: "Planner - Grid",
        activitiesCalendar: "Planner - Calendário",
        apiTokens: "API Tokens",
        changePassword: "Mudar senha",
        computedProperties: "Propriedades Computadas",
        contacts: "Segmente seus contatos",
        contactSegments: "Segmentos de contato",
        creditCard: "Atualizar método de pagamento",
        customFields: "Campos personalizados",
        dashboards: "Dashboards",
        domainVerifications: "Verifique seu domínio de email",
        featureRequests: "Solicite Funcionalidades",
        healthScores: "Gerenciar Pontuação de saúde do cliente",
        installation: "Instalação",
        invitations: "Convide usuários",
        invoices: "Faturas",
        javascript: "Javascript SDK",
        mappedAttributes: "Atributos mapeados",
        messageTemplates: "Modelos de mensagem",
        metrics: "Métricas monitoradas",
        notifications: "Configure as notificações",
        password: "Mudar sua senha",
        playbooksSummary: "Automações",
        playbooksUsers: "Performance do time",
        profile: "Meu perfil",
        segmentCom: "Conectar com Segment.com",
        segmentEdit: "Editar Segmento",
        segmentErrors: "Problemas com seus segmentos",
        settings: "Configurações",
        tags: "Tags",
        taskTypes: "Tipos de tarefas",
        userPreferences: "Minhas preferências",
        users: "Gerenciar usuários",
        webhooks: "Webhooks",
    },
    partials: {
        common: {
            email: "Email",
            actions: "Ações",
            noData: "Sem dados",
            create: "Criar",
            accounts: "Contas",
            contacts: "Contatos",
            cancel: "Cancelar",
            submit: "Enviar",
            add: "Adicionar",
        },
        accessGrants: {
            createdBy: "Solicitado por",
            createdAt: "Solicitado em",
            status: "Status",
            action: "Ação",
            noRecords: "Sem nenhuma solicitação",
            approve: "Aprovar",
            deny: "Negar",
            revoke: "Revogar",
            approved: "Aprovado",
            denied: "Negado",
            pending: "Pendente",
            expired: "Expirado",
            validUntil: "Válido até",
        },
        changePassword: {
            currentPassword: "Senha atual",
            newPassword: "Nova senha",
            submit: "Enviar",
        },
        profile: {
            firstName: "Nome",
            lastName: "Sobrenome",
            emailAddress: "Email",
        },
        notifications: {
            notifyOnTask: "Me notifique quando atribuirem tarefas a mim.",
            notifyOnPlaybookAccount:
                "Me notifique quando contas entrarem nas seguintes automações...",
            notifyOnSegmentAccount:
                "Me notifique quando contas entrarem nos seguintes segmentos...",
            notifyOnSegmentContact:
                "Me notifique quando contatos entrarem nos seguintes segmentos...",
            enabled: "Notificações estão habilitadas.",
            disabled: "Notificações estão desabilitadas.",
        },
        preferences: {
            title: "Preferências",
            value: "Valor",
            timezone: "Fuso Horário",
            locale: "Localização",
            value_unit: "Métrica de receita",
            currency: "Moeda Padrão ",
            score_threshold_bad: "Limiar de pontuação ruim",
            score_threshold_good: "Limiar de pontuação boa",
            nps_threshold_bad: "Limiar de NPS ruim",
            nps_threshold_good: "Limiar de NPS bom",
        },
        customFields: {
            for: "Para",
            label: "Nome",
            type: "Tipo",
            preview: "Preview",
            options: "Opções",
            labelEmpty: "O nome não pode ser nulo",
            groupEmpty: "Selecione um grupo",
            typeEmpty: "Selecione um tipo de campo",
            labelPlaceholder: "Nomeie seu campo...",
            optionPlaceholder: "Adicionar nova opção",
        },
        users: {
            inviteUser: "Convidar usuário",
            name: "Nome",
            email: "Email",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            notSet: "Não definido",
        },
        invitations: {
            sendInvitation: "Enviar Convite",
            inviteUser: "Invite User",
            cancel: "Cancelar",
            send: "Enviar",
            emptyEmail: "O endereço de email não pode ser vazio",
            invalidEmail: "Endereço de email inválido",
        },
        settingsSubnav: {
            profile: {
                title: "Meu perfil",
                myDetails: "Minhas informações",
                changePassword: "Trocar senha",
                accessGrants: "Conceder acesso de suporte",
                notifications: "Configurar notificações",
                userPreferences: "Minhas preferências",
            },
            account: {
                title: "Configurações de conta",
                users: "Usuários",
                rolesPermissions: "Cargos e permissões",
                invitations: "Convites",
                preferences: "Preferências",
                customFields: "Campos customizados",
                tags: "Gerenciar tags",
                taskTypes: "Gerenciar tipos de tarefas",
                domainVerification: "Domínios de envio verificados",
            },
            healthScores: {
                title: "Pontuação de saúde",
                manage: "Gerenciar pontuação de saúde",
            },
            integrations: {
                title: "Integrações",
                apiTokens: "API Tokens",
                sdk: "Javascript SDK",
                mappedAttributes: "Atributos mapeados",
                trackedMetrics: " Métricas monitoradas",
            },
        },
        submit: "Enviar",
    },
    dateUnits: {
        day: "dia | dias",
        week: "semana | semanas",
        month: "mês | meses",
        year: "ano | anos",
    },
    aggregates: {
        count: "Quantidade",
        minimum: "Valor mínimo",
        maximum: "Valor máximo",
        average: "Valor médio",
        sum: "Soma dos valores",
    },
    playbookPerformance: {
        metrics: {
            mrr: "MRR médio",
            arr: "ARR médio",
            csats: "Pontuação de satisfação de consumidor médio (CSAT)",
            nps: "Net Promoter Score™ (NPS)",
            score: "Média primária de saúde do cliente",
        },
        statuses: {
            completed: "Concluído",
            triggered: "Iniciadas",
        },
        outcomes: {
            1: "Com sucesso",
            2: "Sem sucesso",
        },
        summaries: {
            completed:
                "{entities} que completaram essa automação {outcome} {dateRange}",
            triggered: "{entities} que começaram essa automação {dateRange}",
        },
    },
    entityStatistics: {
        labels: {
            add: "Adicionar estatística",
            cancel: "Voltar",
            close: "Fechar",
            searchPlaceholder: "Pesquisar propriedades",
        },
        types: {
            arr: "Receita recorrente anual (ARR)",
            accountCount: "# de Contas",
            contactCount: "# de Contatos",
            csats: "Pontuação de satisfação de Cliente (CSAT)",
            dau: "Usuários ativos diáriamente - (DAUs)",
            dauPercentage: "DAU ÷ Porcentagem de usuários ativos (%)",
            events: "Eventos",
            eventsCount: "Ocorrências de um Evento",
            eventsDelta:
                "Mudança nas ocorrências de um evento ao longo do tempo",
            health: "Pontuação de saúde",
            healthScore: "Pontuação geral de saúde",
            healthScorePercentage: "Saúde geral: % Rank",
            healthScoresCustom: "Pontuação de saúde customizada",
            interactionCounts: "Quantidade de interações importadas",
            mau: "Usuários ativos mensalmente (MAUs)",
            mauPercentage: "MAU ÷ Porcentagem de usuários ativos (%)",
            metrics: "Métricas monitoradas",
            mrr: "Receita recorrente mensal (MRR)",
            nps: "Net Promoter Score™ (NPS)",
            financials: "Receita e finanças",
            stickiness: "Aderência",
            surveys: "Pesquisas e voz do cliente",
            traitsAccounts: "Características de conta",
            traitsIntegrations: "Características de integrações de terceiros",
            traitsCustomFields: "Características customizadas",
            traitsUserProvided: "Características da API & Código de rastreio",
            usageMetrics: "Métricas de uso Web",
            csmScore: "Pontuação CSM",
            webSessions: "Sessões Web",
            webSessionsCount: "Quantidade de sessões",
            webSessionsDelta:
                "Mudança na quantidade de sessões ao longo do tempo",
        },
    },
    activityColumns: {
        labels: {
            add: "Adicionar coluna",
            cancel: "Voltar",
            close: "Fechar",
            searchPlaceholder: "Pesquisar colunas",
            buttonText: "Colunas ({count})",
        },
    },
    activityTypes: {
        account: "Conta",
        accountName: "Nome da conta",
        assignedTo: "Atribuído a",
        assignedToFullName: "Atribuído ao nome",
        assignedToEmail: "Atribuído ao email",
        dates: "Data...",
        comment: "Descrição",
        completedAt: "Data de conclusão",
        completedBy: "Concluída por",
        completedByFullName: "Concluída por nome",
        completedByEmail: "Concluído por email",
        contact: "Contato",
        contactFullName: "Nome de contato",
        createdAt: "Date de criação",
        createdBy: "Criado por",
        createdByFullName: "Criado por nome",
        createdByEmail: "Criado por email",
        dueAt: "Data de entrega (Atividades de automação)",
        id: "Id",
        outcome: "Resultado",
        entityPlaybook: "Instância de automação",
        entityPlaybookEffectiveAt: "Data de início de automação",
        entityPlaybookStatus: "Status da Instância de automação",
        entityPlaybookOutcome: "Resultado da instância de automação",
        playbook: "Definição de automação",
        playbookName: "Nome da automação",
        playbookStatus: "Status da automação",
        playbookAction: "Ação de automação",
        playbookActionName: "Nome da ação de automação",
        playbookActionTypeId: "Tipo da ação de automação",
        playbookActionType: "Tipo da ação de automação",
        playbookActionStatus: "Status da ação de automação",
        scheduledAt: "Data marcada",
        status: "Status",
        title: "Título",
        type: "Tipo de atividade",
        taskType: "Sub tipo de tarefa",
    },
    entityColumns: {
        labels: {
            add: "Adicionar coluna",
            cancel: "Voltar",
            close: "Fechar",
            searchPlaceholder: "Pesquisar colunas",
            buttonText: "Colunas ({count})",
        },
        types: {
            accountName: "Nome da conta",
            fullName: "Nome completo",
            firstName: "Primeiro nome",
            lastName: "Sobrenome",
            email: "Email principal",
            arr: "Receita recorrente anual (ARR)",
            assignedUsers: "Atribuíd a...",
            ces: "Pontuação de experiência de usuário (CES)",
            contractStartDate: "Data de início de contrato",
            contractEndDate: "Data de fim de contrato",
            csats: "Pontuação de satisfação de Cliente (CSAT)",
            currency: "Código da moeda (ex. BRL)",
            customerStartDate: "Data de início de cliente",
            customerEndDate: "Data final do cliente",
            dau: "Usuários ativos diáriamente (DAUs)",
            dauPercentage: "DAU ÷ Porcentagem de usuários ativos (%)",
            events: "Eventos",
            eventsCount: "Ocorrências de um Evento",
            eventsDelta:
                "Mudança nas ocorrências de um evento ao longo do tempo",
            financials: "Receita e finanças",
            health: "Pontuação de saúde",
            healthScore: "Pontuação geral de saúde",
            healthScorePercentage: "Saúde geral: % Rank",
            healthScoresCustom: "Pontuação de saúde customizada",
            interactionCounts: "Quantidade de interações importadas",
            isActive: "É monitorado",
            isChurned: "É cancelado",
            isStarred: "É favorito",
            lastRequestAt: "Last Seen",
            mau: "Usuários ativos mensalmente (MAUs)",
            mauPercentage: "MAU ÷ Porcentagem de usuários ativos (%)",
            mrr: "Receita recorrente mensal (MRR)",
            nps: "Net Promoter Score™ (NPS)",
            status: "Status",
            stickiness: "Aderência",
            surveys: "Pesquisa e voz do cliente",
            traitsAccounts: "Características de conta",
            traitsContacts: "Características de contato",
            traitsIntegrations: "Características de integrações de terceiros",
            traitsCustomFields: "Características customizadas",
            traitsUserProvided: "Características da API & Código de rastreio",
            usageMetrics: "Métricas de uso Web",
            csmScore: "Pontuação CSM",
            tags: "Tags",
            webSessions: "Sessões Web",
            webSessions_ever: "Sessões Web (Desde o início)",
            webSessions_7: "Sessões Web (últimos 7 dias)",
            webSessions_30: "Sessões Web (últimos 30 dias)",
            webSessions_60: "Sessões Web (últimos 60 dias)",
            webSessions_90: "Sessões Web (últimos 90 dias)",
            webSessions_7_delta_percentage:
                "Mudança nas Sessões Web (últimos 7 dias)",
            webSessions_30_delta_percentage:
                "Mudança nas Sessões Web (últimos 30 dias)",
            webSessions_60_delta_percentage:
                "Mudança nas Sessões Web (últimos 60 dias)",
            webSessions_90_delta_percentage:
                "Mudança nas Sessões Web (últimos 90 dias)",
            webSessionsDelta:
                "Mudança na quantidade de sessões ao longo do tempo",
        },
    },
    entityFilters: {
        and: "e",
        addFilter: "Adicionar filtro",
        addFilterGroup: "Adicionar grupo de filtros",
        dateCount: "Contagem de datas",
        dateUnit: "Unidade de data",
        deltaUnit: "Tipo de mudança",
        ownerToggle: {
            all: "Todos",
            my: {
                accounts: "Minhas contas",
                contacts: "Meus contatos",
            },
            none: "Nenhum",
            mine: "Meus",
        },
        search: {
            placeholder: "Pesquisar...",
        },
        deltaUnits: {
            units: "unidades",
            percentage: "porcentagem",
        },
        logic: {
            and: "e",
            or: "ou",
        },
        timeframe: {
            unbounded: {
                label: "Desde o início",
            },
            bounded: {
                label: "nos últimos",
            },
        },
        buttons: {
            done: "Feito",
        },
        validation: {
            requiredMissing: "{value} é obrigatório",
            notNumeric: "{value} não é numérico",
            notDate: "{value} não é uma data válida",
            valueUpperMissing: "Valor superior é obrigatório",
            valueUpperNotNumeric: "Valor superior não é numérico",
            valueLowerMissing: "Valor inferior é obrigatório",
            valueLowerNotNumeric: "Valor inferior não é numérico",
            valueUpperLessThanLower:
                "Valor superior deve ser maior que o valor inferior",
            yearExceeded: "Você pode filtrar até um ano atrás",
            deltaUnitInvalid:
                "Tipo de mudança deve ser 'Porcentagem' ou 'Unidade'",
        },
        properties: {
            eventName: "Nome do evento",
            playbookName: "Automação",
            segmentName: "Segmento",
        },
        labels: {},
        types: {
            accountName: "Nome da conta",
            assignedUsers: "Atribuído a...",
            contractStartDate: "Data de início de contrato",
            contractEndDate: "Data de fim de contrato",
            csats: "Pontuação de satisfação de Cliente (CSAT)",
            currency: "Código da moeda (ex. BRL)",
            customerStartDate: "Data de início de cliente",
            customerEndDate: "Data final do cliente",
            dau: "Usuários ativos diáriamente (DAUs)",
            dauPercentage: "DAU ÷ Porcentagem de usuários ativos (%)",
            email: "Email",
            events: "Eventos",
            eventsCount: "Ocorrências de um Evento",
            eventsDelta:
                "Mudança nas ocorrências de um evento ao longo do tempo",
            financials: "Receita e finanças",
            firstName: "Primeiro nome",
            fullName: "Nome completo",
            health: "Health Scores",
            healthScore: "Pontuação geral de saúde",
            healthScorePercentage: "Saúde geral: % Rank",
            healthScoresCustom: "Pontuação de saúde customizada",
            interactionCounts: "Quantidade de interações importadas",
            isActive: "É monitorado",
            isChurned: "É cancelado",
            isStarred: "É favorito",
            lastName: "Sobrenome",
            mau: "Usuários ativos mensalmente (MAUs)",
            mauPercentage: "MAU ÷ Porcentagem de usuários ativos (%)",
            nps: "Net Promoter Score™ (NPS)",
            playbook: "Automações...",
            segment: "Segmentos...",
            status: "Status",
            stickiness: "Aderência",
            surveys: "Pesquisa e voz do cliente",
            traitsAccounts: "Características de conta",
            traitsIntegrations: "Características de integrações de terceiros",
            traitsCustomFields: "Características customizadas",
            traitsUserProvided: "Características da API & Código de rastreio",
            usageMetrics: "Métricas de uso Web",
            csmScore: "Pontuação CSM",
            webSessions: "Sessões Web",
            webSessionsCount: "Quantidade de sessões",
            webSessionsDelta:
                "Mudança na quantidade de sessões ao longo do tempo",
        },
        tests: {
            booleanTrue: {
                label: "é VERDADEIRO",
                summary: "<span>{label}</span> <span>é VERDADEIRO</span>",
            },
            booleanFalse: {
                label: "é FALSO",
                summary: "<span>{label}</span> <span>é FALSO</span>",
            },
            booleanNotFalse: {
                label: "é VERDADEIRO ou não tem valor",
                summary:
                    "<span>{label}</span> é <span>VERDADEIRO</span> ou <span>não tem valor</span>",
            },
            booleanNotTrue: {
                label: "é FALSO ou não tem valor",
                summary:
                    "<span>{label}</span> é <span>FALSO</span> ou <span>não tem valor</span>",
            },
            dateAfterAbsolute: {
                label: "depois de (data)...",
                summary:
                    "<span>{label}</span> <span>é depois de</span> <span>{value}</span>",
            },
            dateAfterFuture: {
                label: "vai acontecer após...",
                summary:
                    "<span>{label}</span> <span>vai acontecer após</span> <span>{dateCount} {dateUnit}</span> a partir de agora",
            },
            dateAfterPast: {
                label: "Ocorreu após...",
                summary:
                    "<span>{label}</span> <span>Ocorreu após a data de</span> <span>{dateCount} {dateUnit}</span> atrás",
            },
            dateBeforeAbsolute: {
                label: "Antes de (data)...",
                summary:
                    "<span>{label}</span> <span>é antes de</span> <span>{value}</span>",
            },
            dateBeforeFuture: {
                label: "Vai acontecer antes de...",
                summary:
                    "<span>{label}</span> <span>Vai acontecer antes de</span> <span>{dateCount} {dateUnit}</span> a partir de agora",
            },
            dateBeforePast: {
                label: "Aconteceu antes de...",
                summary:
                    "<span>{label}</span> <span>Aconteceu antes de</span> <span>{dateCount} {dateUnit}</span> atrás",
            },
            dateBeforeRelative: {
                label: "Antes de...",
                summary:
                    "<span>{label}</span> <span>Antes de </span> <span>{dateDirection} {dateCount} {dateUnit}</span> a partir de agora",
            },
            dateEquals: {
                label: "é",
                summary:
                    "<span>{label}</span> <span>é</span> <span>{value}</span>",
            },
            isNull: {
                label: "não tem nenhum valor",
                summary:
                    "<span>{label}</span> <span>não tem nenhum valor</span>",
            },
            isNotNull: {
                label: "Tem um valor",
                summary: "<span>{label}</span> <span>Tem um valor</span>",
            },
            numberChangedBetween: {
                label: "Mudou entre",
                summary:
                    "<span>{label}</span> Mudou entre <span>{valueLower}</span> e <span>{valueUpper}{deltaUnit}</span> nos últimos<span>{dateCount} {dateUnit}</span>",
            },
            numberDecreasedGreaterThanOrEqualTo: {
                label: "diminuiu pelo menos",
                summary:
                    "<span>{label}</span> diminui <span>pelo menos{value}{deltaUnit}</span> nos últimos <span>{dateCount} {dateUnit}</span>",
            },
            numberDecreasedGreaterThan: {
                label: "diminuiu mais que",
                summary:
                    "<span>{label}</span> diminuiu <span>mais que {value}{deltaUnit}</span>nos últimos <span>{dateCount} {dateUnit}</span>",
            },
            numberDecreasedLessThanOrEqualTo: {
                label: "diminuiu no máximo",
                summary:
                    "<span>{label}</span> diminuiu <span>no máximo {value}{deltaUnit}</span> nos últimos <span>{dateCount} {dateUnit}</span>",
            },
            numberDecreasedLessThan: {
                label: "diminuiu menos que",
                summary:
                    "<span>{label}</span> diminuiu <span>menos que {value}{deltaUnit}</span> nos últimos <span>{dateCount} {dateUnit}</span>",
            },
            numberIncreasedGreaterThanOrEqualTo: {
                label: "aumentou pelo menos",
                summary:
                    "<span>{label}</span> aumentou <span>pelo menos {value}{deltaUnit}</span> nos últimos <span>{dateCount} {dateUnit}</span>",
            },
            numberIncreasedGreaterThan: {
                label: "aumentou mais que",
                summary:
                    "<span>{label}</span> aumentou <span>mais que {value}{deltaUnit}</span> nos últimos <span>{dateCount} {dateUnit}</span>",
            },
            numberIncreasedLessThanOrEqualTo: {
                label: "aumentou no máximo",
                summary:
                    "<span>{label}</span> aumentou <span>no máximo {value}{deltaUnit}</span> nos últimos <span>{dateCount} {dateUnit}</span>",
            },
            numberIncreasedLessThan: {
                label: "aumentou menos que",
                summary:
                    "<span>{label}</span> aumentou <span>menos que {value}{valueUnit}</span> nos últimos <span>{dateCount} {dateUnit}</span>",
            },
            numberBetweenInTimeFrame: {
                label: "está entre",
                summary: {
                    bounded:
                        "<span>{label}</span> está entre <span>{valueLower}</span> e <span>{valueUpper}</span> nos últimos <span>{dateCount} {dateUnit}</span>",
                    unbounded:
                        "<span>{label}</span> está entre <span>{valueLower}</span> e <span>{valueUpper}</span>",
                },
            },
            numberBetween: {
                label: "está entre",
                summary:
                    "<span>{label}</span> está entre <span>{valueLower}</span> e <span>{valueUpper}</span>",
            },
            numberEqualsInTimeFrame: {
                label: "é igual a",
                summary: {
                    bounded:
                        "<span>{label}</span> é igual a <span>{value}</span> nos últimos <span>{dateCount} {dateUnit}</span>",
                    unbounded:
                        "<span>{label}</span> é igual a <span>{value}</span>",
                },
            },
            numberEquals: {
                label: "é igual a",
                summary: "<span>{label}</span> é igual a <span>{value}</span>",
            },
            numberNotEquals: {
                label: "não é igual a",
                summary:
                    "<span>{label}</span> não é igual a <span>{value}</span>",
            },
            numberGreaterThanInTimeFrame: {
                label: "é maior que",
                summary: {
                    bounded:
                        "<span>{label}</span> é <span>maior que {value}</span> nos últimos <span>{dateCount} {dateUnit}</span>",
                    unbounded:
                        "<span>{label}</span> é <span>maior que {value}</span>",
                },
            },
            numberGreaterThanOrEqualToInTimeFrame: {
                label: "é maior que ou igual a",
                summary: {
                    bounded:
                        "<span>{label}</span> é <span>maior que</span> ou <span>igual a {value}</span> nos últimos <span>{dateCount} {dateUnit}</span>",
                    unbounded:
                        "<span>{label}</span> é <span>maior que</span> ou <span>igual a {value}</span>",
                },
            },
            numberGreaterThanOrEqualTo: {
                label: "é maior que ou igual a",
                summary:
                    "<span>{label}</span> é <span>maior que ou igual a {value}</span>",
            },
            numberGreaterThan: {
                label: "é maior que",
                summary:
                    "<span>{label}</span> é <span>maior que {value}</span>",
            },
            numberLessThanInTimeFrame: {
                label: "é menor que",
                summary: {
                    bounded:
                        "<span>{label}</span> é <span>menor que {value}</span> nos últimos <span>{dateCount} {dateUnit}</span>",
                    unbounded:
                        "<span>{label}</span> é <span>menor que {value}</span>",
                },
            },
            numberLessThanOrEqualToInTimeFrame: {
                label: "é menor que ou igual a",
                summary: {
                    bounded:
                        "<span>{label}</span> é <span>menor que</span> ou <span>igual a {value}</span> nos últimos <span>{dateCount} {dateUnit}</span>",
                    unbounded:
                        "<span>{label}</span> é <span>menor que</span> ou <span>igual a {value}</span>",
                },
            },
            numberLessThanOrEqualTo: {
                label: "é menor que ou igual a",
                summary:
                    "<span>{label}</span> é <span>menor que ou igual a {value}</span>",
            },
            numberLessThan: {
                label: "é menor que",
                summary:
                    "<span>{label}</span> é <span>menor que {value}</span>",
            },
            numberPicklist: {
                label: "é",
                summary: "<span>{label}</span> é <span>{value}</span>",
            },
            playbookCompleted: {
                label: "Concluiu a automação",
                summary: "Automação concluída: <span>{playbookName}</span>",
            },
            playbookNotCompleted: {
                label: "NÃO completou automação",
                summary: "Automação não concluída: <span>{playbookName}</span>",
            },
            playbookCompletedSuccessfully: {
                label: "concluiu uma automação com SUCESSO",
                summary:
                    "Automação concluída com SUCESSO: <span>{playbookName}</span>",
            },
            playbookNotCompletedSuccessfully: {
                label: "concluiu uma automação SEM SUCESSO",
                summary:
                    "Automação concluída SEM SUCESSO: <span>{playbookName}</span>",
            },
            playbookEverIn: {
                label: "está/esteve na automação",
                summary:
                    "Está ou esteve na automação: <span>{playbookName}</span>",
            },
            playbookIn: {
                label: "está na Automação",
                summary: "Na Automação: <span>{playbookName}</span>",
            },
            playbookNotIn: {
                label: "NÃO está na automação",
                summary: "NÃO está na automação: <span>{playbookName}</span>",
            },
            playbookNeverIn: {
                label: "NUNCA esteve na automação",
                summary:
                    "NUNCA esteve na automação: <span>{playbookName}</span>",
            },
            segmentIn: {
                label: "Está no segmento",
                summary: "No segmento: <span>{segmentName}</span>",
            },
            segmentNotIn: {
                label: "NÃO está no segmento",
                summary: "NÃO está no segmento: <span>{segmentName}</span>",
            },
            textBeginsWith: {
                label: "começa com",
                summary: "<span>{label}</span> começa com <span>{value}</span>",
            },
            textBetween: {
                label: "está entre",
                summary:
                    "<span>{label}</span> está entre <span>{valueLower}</span> e <span>{valueUpper}</span>",
            },
            textContains: {
                label: "contém",
                summary: "<span>{label}</span> contém <span>{value}</span>",
            },
            textNotContains: {
                label: "não contém",
                summary: "<span>{label}</span> não contém <span>{value}</span>",
            },
            textEquals: {
                label: "é igual a",
                summary: "<span>{label}</span> é igual a <span>{value}</span>",
            },
            textNotEquals: {
                label: "não é igual a",
                summary:
                    "<span>{label}</span> não é igual a <span>{value}</span>",
            },
            textGreaterThanOrEqualTo: {
                label: "é maior que ou igual a",
                summary:
                    "<span>{label}</span> é <span>maior que ou igual a {value}</span>",
            },
            textGreaterThan: {
                label: "é maior que",
                summary:
                    "<span>{label}</span> é <span> maior que {value}</span>",
            },
            textLessThanOrEqualTo: {
                label: "é menor que ou igual a",
                summary:
                    "<span>{label}</span> é <span>menor que ou igual a {value}</span>",
            },
            textLessThan: {
                label: "é menor que",
                summary:
                    "<span>{label}</span> é <span>menor que {value}</span>",
            },
        },
    },
    infiniteLoading: {
        account: {
            dashboardsNoMore: "Não há mais dashboards.",
            dashboardsNoResults: "Essa conta não tem dashboards.",
            plannerNoMore: "Sem mais itens.",
            plannerNoResults:
                "Essa conta não tem mais itens em aberto do planner.",
            notesNoMore: "Sem mais notas.",
            notesNoResults: "Essa conta não tem notas.",
            filesNoMore: "Sem mais arquivos.",
            filesNoResults: "Essa conta não tem arquivos.",
        },
    },
    interactions: {
        created: "criado",
        updated: "atualizado",
        started: "iniciou",
        continued: "continuou",
        types: {
            call: {
                label: "Ligação",
                created: "Uma nova ligação foi registrada <em>{title}</em>",
                updated: "Uma ligação foi atualizada <em>{title}</em>",
                attributes: {
                    callerName: "Nome do autor da chamada",
                    callerNumber: "Número do autor da chamada",
                    contactName: "Contato",
                    contactNumber: "Número do contato",
                    calledAt: "Ligação feita em",
                    status: "Status",
                    duration: "Duração",
                },
            },
            conversation: {
                label: "Conversa",
                created: "Uma nova conversa foi criada",
                updated: "Uma conversa foi atualizada",
                attributes: {
                    summary: "Resumo",
                },
            },
            deal: {
                label: "Negociação",
                created: "Uma nova negociação foi criada <em>{name}</em>",
                updated: "Uma negociação foi atualzada <em>{name}</em>",
                attributes: {
                    amount: "Quantidade",
                    probability: "Probabilidade de fechar negócio",
                    status: "Status",
                    closedAt: "Fechada em",
                },
            },
            document: {
                label: "Documento",
                created: "Um novo documento foi criado <em>{title}</em>",
                updated: "Um documento foi atualizado <em>{title}</em>",
                attributes: {},
            },
            emailActivity: {
                label: "Email",
                created: "Email Enviado: {subject}",
                updated: "Email Atualizado: {subject}",
                attributes: {
                    openCount: "Quantidade de aberturas",
                    clickCount: "Quantidade de clicks",
                },
            },
            emailThread: {
                label: "Conversa de email",
                created: "Email enviado: {subject}",
                updated: "Email atualizado: {subject}",
                attributes: {
                    subject: "Assunto",
                    body: "Corpo",
                    sender: "Remetente",
                    recipient: "Destinatário",
                    sentAt: "Data de envio",
                },
            },
            event: {
                label: "Evento",
                created: "Um evento foi criado",
                updated: "Um evento foi atualizado",
                attributes: {
                    name: "Nome",
                    date: "Data",
                    location: "Localização",
                    duration: "Duração",
                },
            },
            featureRequest: {
                label: "Pedido de funcionalidade",
                created: "Um pedido de funcionalidade foi criado",
                updated: "Um pedido de funcionalidade foi atualizado",
                attributes: {
                    voteCount: "Quantidade de votos",
                    commentCount: "Quantidade de comentários",
                },
            },
            invoice: {
                label: "Fatura",
                created: "Uma nova fatura foi criada",
                updated: "Uma fatura foi atualizada",
                attributes: {
                    number: "Número",
                    amount: "Valor",
                    currency: "Moeda",
                    dueAt: "Vence em",
                    status: "Status",
                },
            },
            invoiceItem: {
                label: "Linha de item de fatura",
                created: "Uma nova linha de item de fatura foi criada",
                updated: "Uma linha de item de fatura foi atualizada",
                attributes: {
                    name: "Nome",
                    description: "Descrição",
                    amount: "Valor",
                    unitAmount: "Unidade",
                    quantity: "Quantidade",
                },
            },
            note: {
                label: "Nota",
                created: "Uma nova nota foi adicionada <em>{title}</em>",
                updated: "Uma nota foi atualizada <em>{title}</em>",
                attributes: {
                    body: "",
                },
            },
            npsSurvey: {
                label: "Pesquisa de NPS",
                created:
                    "Uma nova pesquisa de NPS <em>{name}</em> foi submetida.",
                updated: "Uma pesquisa de NPS <em>{name}</em> foi atualizada.",
            },
            organization: {
                label: "Organização",
                created: "Uma nova conta <em>{name}</em> foi adicionada.",
                updated: "Uma conta <em>{name}</em> foi atualizada.",
                attributes: {},
            },
            person: {
                label: "Contato",
                created:
                    "Um novo contato <em>{firstName} {lastName}</em> foi adicionado.",
                updated:
                    "Um contato <em>{firstName} {lastName}</em> foi atualizado.",
                attributes: {
                    emailAddress: "Email",
                },
            },
            project: {
                label: "Projeto",
                created: "Um novo projeto <em>{name}</em> foi adicionado.",
                updated: "Um projeto <em>{name}</em> foi atualizado.",
                attributes: {
                    description: "Descrição",
                    startedAt: "Data de início",
                    completedAt: "Data de conclusão",
                    status: "Status",
                },
            },
            providerInteraction: {
                label: "Interação de provedor",
                created: "Um(a) <em>{name}</em> foi adicionado(a).",
                updated: "Um(a) <em>{name}</em> foi atualizado(a).",
                attributes: {},
            },
            refund: {
                label: "Reembolso",
                created: "Um reembolso foi emitido.",
                updated: "Um reembolso foi atualizado.",
                attributes: {
                    number: "Número",
                    amount: "Valor",
                    status: "Status",
                },
            },
            subscription: {
                label: "Assinatura",
                created: "Uma assinatura foi criada.",
                updated: "Uma assinatura foi atualizada.",
                attributes: {
                    amount: "Valor",
                    currency: "Moeda",
                    startDate: "Data de ínicio",
                    endDate: "Data de fim",
                    quantity: "Quantidade",
                    planInterval: "Intervalo de plano",
                    status: "Status",
                },
            },
            subscriptionProduct: {
                label: "Produto de assinatura",
                created: "Um produto de assinatura foi criado.",
                updated: "Um produto de assinatura foi atualizado.",
                attributes: {},
            },
            survey: {
                label: "Pesquisa",
                created: "Uma pesquisa foi recebida.",
                updated: "Uma pesquisa foi atualizada.",
                attributes: {
                    score: "Pontuação",
                    comments: "Comentários",
                },
            },
            surveyCes: {
                label: "Pesquisa CES",
                created: "Uma pesquisa CES foi recebida.",
                updated: "UMa pesquisa CES foi atualizada.",
                attributes: {
                    score: "POntuação",
                    comments: "Comentários",
                },
            },
            surveyCsats: {
                label: "Pesquisa CSAT",
                created: "Uma pesquisa CSAT foi recebida.",
                updated: "Uma pesquisa CSAT foi atualizada.",
                attributes: {
                    score: "Pontuação",
                    comments: "Comentários",
                },
            },
            surveyNps: {
                label: "Pesquisa NPS",
                created: "Uma pesquisa NPS foi recebida.",
                updated: "Uma pesquisa NPS foi atualizada.",
                attributes: {
                    score: "Pontuação",
                    comments: "Comentários",
                },
            },
            task: {
                label: "Tarefa",
                created: "Uma nova tarefa <em>{name}</em> foi adicionada.",
                updated: "Uma tarefa <em>{name}</em> foi atualizada.",
                attributes: {
                    description: "Descrição",
                    status: "Status",
                    priority: "Prioridade",
                    closedAt: "Fechada em",
                },
            },
            ticket: {
                label: "Ticket",
                created: "Um novo ticket <em>{name}</em> foi adicionado.",
                updated: "Um ticket <em>{name}</em> foi atualizado.",
                attributes: {
                    description: "Descrição",
                    number: "Número",
                    probability: "Probabilidade",
                    priority: "Prioridade",
                    rating: "Classificação",
                    source: "Fonte",
                    status: "Status",
                    closedAt: "Fechado em",
                },
            },
            transaction: {
                label: "Transação",
                created: "Uma nova transação foi adicionada.",
                updated: "Uma transação foi atualizada.",
                attributes: {
                    amount: "Valor",
                    currency: "Moeda",
                },
            },
            transactionalEmail: {
                label: "Email transacional",
                created: "Email Enviado: {subject}",
                updated: "Email Atualizado: {subject}",
                attributes: {
                    openCount: "Quantidade de aberturas",
                    clickCount: "Quantidade de cliques",
                },
            },
        },
    },
    tasks: {},
    notices: {
        /** GROUP BY VIEW **/
        settings: {
            updated: "Suas configurações foram atualizadas.",
        },
    },
};

export default pt;
