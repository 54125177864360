const en = {
    i: {
        datepicker: {
            weeks: {
                sun: "Su",
                mon: "M",
                tue: "Tu",
                wed: "W",
                thu: "Th",
                fri: "F",
                sat: "Sa",
            },
        },
    },
    dataTypes: {
        STRING: "String",
        INTEGER: "Integer",
        DECIMAL: "Decimal",
        UNIX_SECONDS: "Unix Time (Seconds)",
        UNIX_MILLISECONDS: "Unix Time (Milliseconds)",
        DATE_ISO8601: "Date (YYYY-MM-DD)",
        DATE_MM_DD_YYYY: "Date (MM-DD-YYYY)",
        DATE_DD_MM_YYYY: "Date (DD-MM-YYYY)",
        CURRENCY_CENTS: "Currency in Cents (1 dollar = 100)",
        PERCENTAGE: "Percentage (0.0 - 1.0)",
        PERCENTAGE_INT: "Percentage (0 - 100)",
        BOOLEAN: "Boolean",
    },
    transformationTypes: {
        INTEGER: "Integer",
        DECIMAL: "Decimal",
        BOOLEAN: "Boolean",
        DATE_ISO8601: "Date (YYYY-MM-DD)",
        DATE_MM_DD_YYYY: "Date (MM-DD-YYYY)",
        DATE_DD_MM_YYYY: "Date (DD-MM-YYYY)",
        DATE_ISO8601_TIME: "Date (YYYY-MM-DD H:I:S)",
        DATE_MM_DD_YYYY_TIME: "Date (MM-DD-YYYY H:I:S)",
        DATE_DD_MM_YYYY_TIME: "Date (DD-MM-YYYY H:I:S)",
        UNIX_SECONDS: "Unix Time (Seconds)",
        UNIX_MILLISECONDS: "Unix Time (Milliseconds)",
        CURRENCY: "Currency",
        PERCENTAGE: "Percentage",
    },
    countries: [
        "Afghanistan",
        "Åland Islands",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Congo",
        "Congo, Democratic Republic of the",
        "Cook Islands",
        "Costa Rica",
        "Côte d'Ivoire",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands (Malvinas)",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See (Vatican City State)",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran (Islamic Republic of)",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea (Democratic People's Republic of)",
        "Korea, Republic of",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Macedonia, the former Yugoslav Republic of",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia (Federated States of)",
        "Moldova, Republic of",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Réunion",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan, Province of China",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States Minor Outlying Islands",
        "United States of America",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela (Bolivarian Republic of)",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
    ],
    pageTitles: {
        accessGrants: "Grant Support Access",
        accountPreferences: "Account Preferences",
        accounts: "Segment Your Accounts",
        accountsChurned: "Churned Accounts",
        accountsTracked: "Tracked Accounts",
        accountSegments: "Account Segments",
        activitiesFollowing: "Planner - Following",
        activitiesToday: "Planner - Today",
        activitiesOverdue: "Planner - Overdue",
        activitiesUpcoming: "Planner - Upcoming",
        activitiesCompleted: "Planner - Completed",
        activitiesGrid: "Planner - Grid",
        activitiesCalendar: "Planner - Calendar",
        apiTokens: "API Tokens",
        changePassword: "Change Password",
        computedProperties: "Computed Properties",
        contacts: "Segment Your Contacts",
        contactSegments: "Contact Segments",
        creditCard: "Update Your Payment Method",
        csvUpload: "Upload Accounts and Contacts in CSV File",
        customFields: "Custom Fields",
        dashboards: "Dashboards",
        domainVerifications: "Verify Your Email Domain",
        featureRequests: "Request Features",
        healthScores: "Manage Health Scores",
        installation: "Installation",
        integrations: "Integrations",
        invitations: "Invite Users",
        invoices: "Invoices",
        javascript: "Javascript SDK",
        mappedAttributes: "Mapped Attributes",
        messageTemplates: "Message Templates",
        metrics: "Tracked Metrics",
        notifications: "Configure Notifications",
        password: "Change Your Password",
        playbooksLogs: "Playbook Logs",
        playbooksSummary: "Playbooks",
        playbooksUsers: "Team Performance",
        profile: "My Profile",
        provider: "Connect Akita and {providerName}",
        providers: "Available Integrations",
        segmentCom: "Connect with Segment.com",
        segmentEdit: "Edit Segment",
        segmentErrors: "Problems with Your Segments",
        settings: "Settings",
        slack: "Slack Settings",
        tags: "Tags",
        taskTypes: "Task Sub-Types",
        userPreferences: "My Preferences",
        users: "Manage Users",
        webhooks: "Webhooks",
    },
    partials: {
        common: {
            email: "Email",
            actions: "Actions",
            noData: "No data",
            create: "Create",
            accounts: "Accounts",
            contacts: "Contacts",
            cancel: "Cancel",
            submit: "Submit",
            add: "Add",
            remove: "Remove",
        },
        accessGrants: {
            createdBy: "Requested by",
            createdAt: "Requested on",
            status: "Status",
            action: "Action",
            noRecords: "No requests yet",
            approve: "Approve",
            deny: "Deny",
            revoke: "Revoke",
            approved: "Approved",
            denied: "Denied",
            pending: "Pending",
            expired: "Expired",
            validUntil: "Valid until",
        },
        changePassword: {
            currentPassword: "Current Password",
            newPassword: "New Password",
            submit: "Update",
        },
        profile: {
            firstName: "First Name",
            lastName: "Last Name",
            emailAddress: "Email Address",
        },
        notifications: {
            notifyOnTask: "Notify me when I am assigned new tasks.",
            notifyOnPlaybookAccount:
                "Notify me when accounts join the following Playbooks…",
            notifyOnSegmentAccount:
                "Notify me when accounts join the following Segments…",
            notifyOnSegmentContact:
                "Notify me when contacts join the following Segments…",
            enabled: "Notifications are enabled.",
            disabled: "Notifications are disabled.",
        },
        preferences: {
            title: "Preferences",
            value: "Value",
            timezone: "Timezone",
            locale: "Locale",
            value_unit: "Revenue Metric",
            currency: "Default Currency",
            score_threshold_bad: "Poor Score Threshold",
            score_threshold_good: "Good Score Threshold",
            nps_threshold_bad: "Poor NPS Threshold",
            nps_threshold_good: "Good NPS Threshold",
            label: "Label",
            type: "Type",
            preview: "Preview",
        },
        customFields: {
            for: "For",
            label: "Label",
            type: "Type",
            preview: "Preview",
            options: "Options",
            labelEmpty: "The name cannot be empty",
            groupEmpty: "Please select the group",
            typeEmpty: "Please select the field type",
            labelPlaceholder: "Label your field...",
            optionPlaceholder: "Add new option",
        },
        users: {
            inviteUser: "Invite User",
            name: "Name",
            email: "Email",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            notSet: "Not set",
        },
        invitations: {
            sendInvitation: "Send Invitation",
            cancel: "Cancel",
            send: "Send",
            emptyEmail: "Email address cannot be empty",
            invalidEmail: "Invalid email address",
        },
        integrations: {
            integrationObjects: {
                statuses: {
                    1: "Pending",
                    2: "Syncing",
                    3: "Sync Failed",
                    4: "Purging",
                    5: "Deleting",
                    6: "Not Configured",
                    10: "Not Selected",
                },
            },
            integrations: {
                status: "Integration Status: {status}",
                statuses: {
                    1: "Paused",
                    2: "Active",
                    3: "Authentication Failed",
                    4: "Pending",
                    5: "Purging",
                    6: "Deleting",
                    10: "Not Integrated",
                },
                actions: {
                    pause: "Pause",
                    activate: "Enable",
                    purge: "Purge Data",
                    delete: "Disconnect",
                },
            },
            provider: {
                about: "About {name}",
                data: "Data We Collect From {name}",
                connect: "Get Started!",
            },
        },
        settingsSubnav: {
            profile: {
                title: "My Profile",
                myDetails: "My Details",
                changePassword: "Change Password",
                accessGrants: "Grant Account Access",
                notifications: "Configure Notifications",
                userPreferences: "My Preferences",
            },
            account: {
                title: "Account Settings",
                users: "Users",
                rolesPermissions: "Roles and Permissions",
                invitations: "Invitations",
                preferences: "Preferences",
                customFields: "Custom Fields",
                tags: "Manage Tags",
                taskTypes: "Manage Task Sub-Types",
                domainVerification: "Verified Sending Domains",
                configureTrackedAccounts: "Define Tracked Accounts",
                configureChurnedAccounts: "Define Churned Accounts",
            },
            healthScores: {
                title: "Health Scores",
                manage: "Manage Health Scores",
            },
            integrations: {
                title: "Integrations",
                apiTokens: "API Tokens",
                sdk: "Javascript SDK",
                thirdPartyIntegrations: "Integrations",
                mappedAttributes: "Mapped Attributes",
                trackedMetrics: " Tracked Metrics",
                pending: "Pending",
                active: "Active",
                refreshFailed: "Refresh Failed",
            },
            customFields: {},
        },
        submit: "Submit",
    },
    dateUnits: {
        day: "day | days",
        week: "week | weeks",
        month: "month | months",
        year: "year | years",
    },
    aggregates: {
        count: "Count",
        minimum: "Minimum Value",
        maximum: "Maximum Value",
        average: "Average Value",
        sum: "Sum of Values",
    },
    playbookPerformance: {
        metrics: {
            mrr: "Average MRR",
            arr: "Average ARR",
            csats: "Average Customer Satisfaction Score (CSAT)",
            nps: "Net Promoter Score™ (NPS)",
            score: "Avergae Primary Health Score",
        },
        statuses: {
            completed: "Completed",
            triggered: "Triggered",
        },
        outcomes: {
            1: "Successfully",
            2: "Unsuccessfully",
        },
        summaries: {
            completed:
                "{entities} that completed this playbook {outcome} {dateRange}",
            triggered: "{entities} that started this playbook {dateRange}",
        },
    },
    entityStatistics: {
        labels: {
            add: "Add Statistic",
            cancel: "Back",
            close: "Close",
            searchPlaceholder: "Search Properties",
        },
        types: {
            arr: "Annual Recurring Revenue (ARR)",
            ARR: "Annual Recurring Revenue (ARR)",
            accountCount: "# of Accounts",
            contactCount: "# of Contacts",
            csats: "Customer Satisfaction Score (CSAT)",
            dau: "Daily Active Users (DAUs)",
            dauPercentage: "License Utilization % (DAU ÷ Active Licenses)",
            events: "Events",
            eventsCount: "Occurrences of an Event",
            eventsDelta: "Change in Occurrences of an Event Over Time",
            financials: "Revenue and Financials",
            health: "Health Scores",
            healthScore: "Overall Health Score",
            healthScoreDelta: "Change in Overall Health Score Over Time",
            healthScorePercentage: "Overall Health: % Rank",
            healthScoresCustom: "Custom Health Scores",
            interactionCounts: "Imported Interaction Counts",
            mau: "Monthly Active Users (MAUs)",
            mauPercentage: "License Utilization % (MAU ÷ Active Licenses)",
            metrics: "Tracked Metrics",
            mrr: "Monthly Recurring Revenue (MRR)",
            MRR: "Monthly Recurring Revenue (MRR)",
            nps: "Net Promoter Score™ (NPS)",
            pageViews: "Page Views",
            root: "Root",
            sessionCount: "Session Counts",
            stickiness: "Stickiness",
            surveys: "Surveys and Voice of Customer",
            traitsAccounts: "Account Traits",
            traitsIntegrations: "3rd-Party Integration Traits",
            traitsCustomFields: "Custom Traits",
            traitsComputed: "Computed Traits",
            traitsUserProvided: "API & Tracking Code Traits",
            usageMetrics: "Usage Metrics",
            csmScore: "CSM Score",
            csmScoreLastUpdatedAt: "CSM Score Last Updated Date",
            csmScoreLastComment: "CSM Score Last Comment",
            taskLastComment: "Last Task Comment",
            webSessions: "Web Sessions",
            webSessionsCount: "Session Count",
            webSessionsDelta: "Change in Session Count Over Time",
        },
    },
    activityColumns: {
        labels: {
            add: "Add Column",
            cancel: "Back",
            close: "Close",
            searchPlaceholder: "Search Columns",
            buttonText: "Columns ({count})",
        },
    },
    activityTypes: {
        account: "Account",
        accountName: "Account Name",
        assignedTo: "Assigned To",
        assignedToFullName: "Assigned To Name",
        assignedToEmail: "Assigned To Email",
        dates: "Date...",
        comment: "Description",
        completedAt: "Completed Date",
        completedBy: "Completed By",
        completedByFullName: "Completed By Name",
        completedByEmail: "Completed By Email",
        contact: "Contact",
        contactFullName: "Contact Name",
        createdAt: "Created Date",
        createdBy: "Created By",
        createdByFullName: "Created By Name",
        createdByEmail: "Created By Email",
        dueAt: "Due Date (Playbook Activities)",
        id: "Id",
        outcome: "Outcome",
        entityPlaybook: "Playbook Instance",
        entityPlaybookEffectiveAt: "Date Playbook Began",
        entityPlaybookStatus: "Playbook Instance Status",
        entityPlaybookOutcome: "Playbook Instance Outcome",
        playbook: "Playbook Definition",
        playbookName: "Playbook Name",
        playbookStatus: "Playbook Status",
        playbookAction: "Playbook Action",
        playbookActionName: "Playbook Action Name",
        playbookActionTypeId: "Playbook Action Type",
        playbookActionType: "Playbook Action Type",
        playbookActionStatus: "Playbook Action Status",
        scheduledAt: "Scheduled Date",
        status: "Status",
        title: "Title",
        type: "Activity Type",
        taskType: "Task Sub-Type",
    },
    entityColumns: {
        labels: {
            add: "Add Column",
            cancel: "Back",
            close: "Close",
            empty: "Remove All Columns",
            searchPlaceholder: "Search Columns",
            buttonText: "Columns ({count})",
        },
        types: {
            accountCreatedAt: "Date Added to Akita",
            accountName: "Account Name",
            activities: "Activities",
            activitiesTaskDatesCompletedTodoAll: "Last Task Completed",
            activitiesTaskDatesCompletedNoteAll: "Last Note Added",
            activitiesTaskDatesCompletedMessageAll: "Last Message Sent",
            fullName: "Full Name",
            firstName: "First Name",
            lastName: "Last Name",
            email: "Primary Email",
            activeLicenseCount: "Active License Count",
            arr: "Annual Recurring Revenue (ARR)",
            assignedUsers: "Assigned To...",
            ces: "Customer Experience Score (CES)",
            contractStartDate: "Contract Start Date",
            contractEndDate: "Contract End (Renewal) Date",
            csats: "Customer Satisfaction Score (CSAT)",
            currency: "Currency Code (ex. USD)",
            customerStartDate: "Customer Start Date",
            customerEndDate: "Customer End (Churn) Date",
            dau: "Daily Active Users (DAUs)",
            dauPercentage: "License Utilization % (DAU ÷ Active Licenses)",
            events: "Events",
            eventsCount: "Occurrences of an Event",
            eventsDelta: "Change in Occurrences of an Event Over Time",
            financials: "Revenue and Financials",
            health: "Health Scores",
            healthScore: "Overall Health Score",
            healthScorePercentage: "Overall Health: % Rank",
            healthScoresCustom: "Custom Health Scores",
            interactions: "Imported Interactions",
            interactionCounts: "Interaction Counts",
            interactionDates: "Interaction Dates",
            interactionsMostRecentConversation: "Most Recent Conversation",
            interactionsMostRecentDeal: "Most Recent Deal",
            interactionsMostRecentInvoice: "Most Recent Invoice",
            interactionsMostRecentTicket: "Most Recent Ticket",
            interactionsDatesEmailThreads: "Email Threads",
            interactionsDatesEmailThreadsFirstReceived:
                "First Email Received On",
            interactionsDatesEmailThreadsLastReceived:
                "Most Recent Email Received On",
            interactionsDatesEmailThreadsFirstSent: "First Email Sent On",
            interactionsDatesEmailThreadsLastSent: "Most Recent Email Sent On",
            isActive: "Is Tracked",
            isChurned: "Is Churned",
            isStarred: "Is Starred",
            lastRequestAt: "Last Seen",
            mau: "Monthly Active Users (MAUs)",
            mauPercentage: "License Utilization % (MAU ÷ Active Licenses)",
            mrr: "Monthly Recurring Revenue (MRR)",
            nps: "Net Promoter Score™ (NPS)",
            parentOrganizationId: "Parent Account Id",
            parentOrganizationName: "Parent Account",
            root: "Root",
            status: "Status",
            stickiness: "Stickiness",
            surveys: "Surveys and Voice of Customer",
            traitsAccounts: "Account Traits",
            traitsContacts: "Contact Traits",
            traitsIntegrations: "3rd-Party Integration Traits",
            traitsCustomFields: "Custom Traits",
            traitsComputed: "Computed Traits",
            traitsUserProvided: "API & Tracking Code Traits",
            usageMetrics: "Web Usage Metrics",
            csmScore: "CSM Score",
            csmScoreLastUpdatedAt: "CSM Score Last Updated Date",
            tags: "Tags",
            csmScoreLastComment: "CSM Score Last Comment",
            taskLastComment: "Last Task Comment",
            webSessions: "Web Sessions",
            webSessions_ever: "Count: All-time",
            webSessions_7: "Sessions Last 7 Days",
            webSessions_30: "Sessions Last 30 Days",
            webSessions_60: "Sessions Last 60 Days",
            webSessions_90: "Sessions Last 90 Days",
            webSessions_7_delta_percentage: "Session Change Last 7 Days",
            webSessions_30_delta_percentage: "Session Change Last 30 Days",
            webSessions_60_delta_percentage: "Session Change Last 60 Days",
            webSessions_90_delta_percentage: "Session Change Last 90 Days",
            webSessionsDelta: "Change in Session Count Over Time",
        },
    },
    entityFilters: {
        and: "and",
        addFilter: "Add Filter",
        addFilterGroup: "Add Filter Group",
        comparators: {
            greaterThan: "Greater Than",
            greaterThanOrEqualTo: "Greater Than or Equal To",
            equalTo: "Equal To",
            lessThan: "Less Than",
            lessThanOrEqualTo: "Less Than or Equal To",
        },
        dateCount: "Date Count",
        dateUnit: "Date Unit",
        deltaUnit: "Change Type",
        ownerToggle: {
            all: "All",
            my: {
                accounts: "My Accounts",
                contacts: "My Contacts",
            },
            none: "None",
            mine: "Mine",
        },
        search: {
            placeholder: "Search...",
        },
        deltaUnits: {
            units: "units",
            percentage: "percent",
        },
        logic: {
            and: "and",
            or: "or",
        },
        timeframe: {
            unbounded: {
                label: "ever",
            },
            bounded: {
                label: "in the last",
            },
        },
        buttons: {
            done: "Done",
        },
        validation: {
            requiredMissing: "{value} is required",
            notNumeric: "{value} is not numeric",
            notDate: "{value} is not a date",
            valueUpperMissing: "Upper value is required",
            valueUpperNotNumeric: "Upper value is not numeric",
            valueLowerMissing: "Lower value is required",
            valueLowerNotNumeric: "Lower value is not numeric",
            valueUpperLessThanLower:
                "Upper value must be greater than the lower value",
            yearExceeded: "You can only query back 1 year",
            deltaUnitInvalid: "Change type must be 'Percentage' or 'Units'",
        },
        properties: {
            eventName: "Event Name",
            playbookName: "Playbook",
            segmentName: "Segment",
            tagName: "Tag",
            pageViewsPageName: "# of Page Views by Name",
            pageViewsPagePath: "# Page Views by Path",
            compareToProperty: "Another Property...",
        },
        labels: {},
        types: {
            accountCreatedAt: "Date Added to Akita",
            accountName: "Account Name",
            activeLicenseCount: "Active License Count",
            activities: "Activities",
            activitiesTaskDatesCompletedTodoAll: "Last Task Completed",
            activitiesTaskDatesCompletedNoteAll: "Last Note Added",
            activitiesTaskDatesCompletedMessageAll: "Last Message Sent",
            arr: "Annual Recurring Revenue (ARR)",
            assignedUsers: "Assigned To...",
            contractStartDate: "Contract Start Date",
            contractEndDate: "Contract End Date",
            csats: "Customer Satisfaction Score (CSAT)",
            currency: "Currency Code (ex. USD)",
            customerStartDate: "Customer Start Date",
            customerEndDate: "Customer End Date",
            dau: "Daily Active Users (DAUs)",
            dauPercentage: "DAU ÷ Active Users Percentage (%)",
            email: "Email",
            events: "Events",
            eventName: "Event Name",
            eventsCount: "Occurrences of an Event",
            eventsDelta: "Change in Occurrences of an Event Over Time",
            financials: "Revenue and Financials",
            firstName: "First Name",
            fullName: "Full Name",
            health: "Health Scores",
            healthScore: "Overall Health Score",
            healthScoreDelta: "Change in Overall Health Score Over Time",
            healthScorePercentage: "Overall Health: % Rank",
            healthScoresCustom: "Custom Health Scores",
            interactions: "Imported Interactions",
            interactionCounts: "Interaction Counts",
            interactionDates: "Interaction Dates",
            interactionsMostRecentConversation: "Most Recent Conversation",
            interactionsMostRecentDeal: "Most Recent Deal",
            interactionsMostRecentInvoice: "Most Recent Invoice",
            interactionsMostRecentTicket: "Most Recent Ticket",
            interactionsDatesEmailThreads: "Email Threads",
            interactionsDatesEmailThreadsFirstReceived:
                "First Email Received On",
            interactionsDatesEmailThreadsLastReceived:
                "Most Recent Email Received On",
            interactionsDatesEmailThreadsFirstSent: "First Email Sent On",
            interactionsDatesEmailThreadsLastSent: "Most Recent Email Sent On",
            isActive: "Is Tracked",
            isChurned: "Is Churned",
            isStarred: "Is Starred",
            lastName: "Last Name",
            lastRequestAt: "Last Seen",
            mau: "Monthly Active Users (MAUs)",
            mauPercentage: "MAU ÷ Active Users Percentage (%)",
            mrr: "Monthly Recurring Revenue (MRR)",
            nps: "Net Promoter Score™ (NPS)",
            npsDelta: "Change in NPS™ Over Time",
            pageViews: "Page Views",
            pageViewsCountName: "# Page Views (by Name)",
            pageViewsCountPath: "# Page Views (by Path)",
            parentOrganizationId: "Parent Account Id",
            parentOrganizationName: "Parent Account",
            playbook: "Playbooks...",
            playbookName: "Playbook Name",
            root: "Root",
            segment: "Segments...",
            segmentInNotIn: "(Not) in Segment",
            segmentEverNeverIn: "(N)ever in Segment",
            segmentFirstExitRecordedAt: "First Left Segment",
            segmentLastExitRecordedAt: "Most Recently Left Segment",
            segmentFirstEnterRecordedAt: "First Joined Segment",
            segmentLastEnterRecordedAt: "Most Recently Joined Segment",
            segmentName: "Segment Name",
            status: "Status",
            stickiness: "Stickiness",
            surveys: "Surveys and Voice of Customer",
            tags: "Tags...",
            tagName: "Tag Name",
            trackedMetrics: "Tracked Metrics",
            traitsAccounts: "Account Traits",
            traitsIntegrations: "3rd-Party Integration Traits",
            traitsCustomFields: "Custom Traits",
            traitsComputed: "Computed Traits",
            traitsUserProvided: "API & Tracking Code Traits",
            usageMetrics: "Web Usage Metrics",
            csmScore: "CSM Score",
            csmScoreLastUpdatedAt: "CSM Score Last Updated Date",
            webSessions: "Web Sessions",
            webSessionsCount: "Session Count",
            webSessionsDelta: "Change in Session Count Over Time",
        },
        tests: {
            booleanTrue: {
                label: "is TRUE",
                summary: "<span>{label}</span> <span>is TRUE</span>",
            },
            booleanFalse: {
                label: "is FALSE",
                summary: "<span>{label}</span> <span>is FALSE</span>",
            },
            booleanNotFalse: {
                label: "is TRUE or has no value",
                summary:
                    "<span>{label}</span> is <span>TRUE</span> or <span>has no value</span>",
            },
            booleanNotTrue: {
                label: "is FALSE or has no value",
                summary:
                    "<span>{label}</span> is <span>FALSE</span> or <span>has no value</span>",
            },
            compareTo: {
                label: "is >, <, = another property...",
                summary:
                    "<span>{label}</span> <span>is {comparator}</span> <span>{compareToProperty}</span>",
            },
            dateAfterAbsolute: {
                label: "after (date)...",
                summary:
                    "<span>{label}</span> <span>is after</span> <span>{value}</span>",
            },
            dateAfterFuture: {
                label: "will occur after...",
                summary:
                    "<span>{label}</span> <span>will occur after</span> <span>{dateCount} {dateUnit}</span> from now",
            },
            dateAfterPast: {
                label: "occurred after...",
                summary:
                    "<span>{label}</span> <span>occurred after</span> <span>{dateCount} {dateUnit}</span> ago",
            },
            dateBeforeAbsolute: {
                label: "before (date)...",
                summary:
                    "<span>{label}</span> <span>is before</span> <span>{value}</span>",
            },
            dateBeforeFuture: {
                label: "will occur before...",
                summary:
                    "<span>{label}</span> <span>will occur before</span> <span>{dateCount} {dateUnit}</span> from now",
            },
            dateBeforePast: {
                label: "occurred before...",
                summary:
                    "<span>{label}</span> <span>occurred before</span> <span>{dateCount} {dateUnit}</span> ago",
            },
            dateBeforeRelative: {
                label: "is before...",
                summary:
                    "<span>{label}</span> <span>is before</span> <span>{dateDirection} {dateCount} {dateUnit}</span> from now",
            },
            dateEquals: {
                label: "is",
                summary:
                    "<span>{label}</span> <span>is</span> <span>{value}</span>",
            },
            isNull: {
                label: "does not have any value",
                summary:
                    "<span>{label}</span> <span>does not have a value</span>",
            },
            isNotNull: {
                label: "has a value",
                summary: "<span>{label}</span> <span>has a value</span>",
            },
            isOverdue: {
                label: "is overdue",
                summary: "<span>{label}</span> <span>is overdue</span>",
            },
            numberChangedBetween: {
                label: "has changed between",
                summary:
                    "<span>{label}</span> has changed between <span>{valueLower}</span> and <span>{valueUpper}{deltaUnit}</span> in the last <span>{dateCount} {dateUnit}</span>",
            },
            numberDecreasedGreaterThanOrEqualTo: {
                label: "has decreased at least",
                summary:
                    "<span>{label}</span> has <span>decreased at least {value}{deltaUnit}</span> in the last <span>{dateCount} {dateUnit}</span>",
            },
            numberDecreasedGreaterThan: {
                label: "has decreased more than",
                summary:
                    "<span>{label}</span> has <span>decreased more than {value}{deltaUnit}</span> in the last <span>{dateCount} {dateUnit}</span>",
            },
            numberDecreasedLessThanOrEqualTo: {
                label: "has decreased at most",
                summary:
                    "<span>{label}</span> has <span>decreased at most {value}{deltaUnit}</span> in the last <span>{dateCount} {dateUnit}</span>",
            },
            numberDecreasedLessThan: {
                label: "has decreased less than",
                summary:
                    "<span>{label}</span> has <span>decreased less than {value}{deltaUnit}</span> in the last <span>{dateCount} {dateUnit}</span>",
            },
            numberIncreasedGreaterThanOrEqualTo: {
                label: "has increased at least",
                summary:
                    "<span>{label}</span> has <span>increased at least {value}{deltaUnit}</span> in the last <span>{dateCount} {dateUnit}</span>",
            },
            numberIncreasedGreaterThan: {
                label: "has increased more than",
                summary:
                    "<span>{label}</span> has <span>increased more than {value}{deltaUnit}</span> in the last <span>{dateCount} {dateUnit}</span>",
            },
            numberIncreasedLessThanOrEqualTo: {
                label: "has increased at most",
                summary:
                    "<span>{label}</span> has <span>increased at most {value}{deltaUnit}</span> in the last <span>{dateCount} {dateUnit}</span>",
            },
            numberIncreasedLessThan: {
                label: "has increased less than",
                summary:
                    "<span>{label}</span> has <span>increased less than {value}{deltaUnit}</span> in the last <span>{dateCount} {dateUnit}</span>",
            },
            numberBetweenInTimeFrame: {
                label: "is between",
                summary: {
                    bounded:
                        "<span>{label}</span> is between <span>{valueLower}</span> and <span>{valueUpper}</span> in the last <span>{dateCount} {dateUnit}</span>",
                    unbounded:
                        "<span>{label}</span> is between <span>{valueLower}</span> and <span>{valueUpper}</span>",
                },
            },
            numberBetween: {
                label: "is between",
                summary:
                    "<span>{label}</span> is between <span>{valueLower}</span> and <span>{valueUpper}</span>",
            },
            numberUnchanged: {
                label: "is unchanged",
                summary:
                    "<span>{label}</span> is unchanged in the last <span>{dateCount} {dateUnit}</span>",
            },
            numberEqualsInTimeFrame: {
                label: "is equal to",
                summary: {
                    bounded:
                        "<span>{label}</span> is equal to <span>{value}</span> in the last <span>{dateCount} {dateUnit}</span>",
                    unbounded:
                        "<span>{label}</span> is equal to <span>{value}</span>",
                },
            },
            numberEquals: {
                label: "is equal to",
                summary:
                    "<span>{label}</span> is equal to <span>{value}</span>",
            },
            numberNotEquals: {
                label: "is not equal to",
                summary:
                    "<span>{label}</span> is not equal to <span>{value}</span>",
            },
            numberGreaterThanInTimeFrame: {
                label: "is greater than",
                summary: {
                    bounded:
                        "<span>{label}</span> is <span>greater than {value}</span> in the last <span>{dateCount} {dateUnit}</span>",
                    unbounded:
                        "<span>{label}</span> is <span>greater than {value}</span>",
                },
            },
            numberGreaterThanOrEqualToInTimeFrame: {
                label: "is greater than or equal to",
                summary: {
                    bounded:
                        "<span>{label}</span> is <span>greater than</span> or <span>equal to {value}</span> in the last <span>{dateCount} {dateUnit}</span>",
                    unbounded:
                        "<span>{label}</span> is <span>greater than</span> or <span>equal to {value}</span>",
                },
            },
            numberGreaterThanOrEqualTo: {
                label: "is greater than or equal to",
                summary:
                    "<span>{label}</span> is <span>greater than or equal to {value}</span>",
            },
            numberGreaterThan: {
                label: "is greater than",
                summary:
                    "<span>{label}</span> is <span>greater than {value}</span>",
            },
            numberLessThanInTimeFrame: {
                label: "is less than",
                summary: {
                    bounded:
                        "<span>{label}</span> is <span>less than {value}</span> in the last <span>{dateCount} {dateUnit}</span>",
                    unbounded:
                        "<span>{label}</span> is <span>less than {value}</span>",
                },
            },
            numberLessThanOrEqualToInTimeFrame: {
                label: "is less than or equal to",
                summary: {
                    bounded:
                        "<span>{label}</span> is <span>less than</span> or <span>equal to {value}</span> in the last <span>{dateCount} {dateUnit}</span>",
                    unbounded:
                        "<span>{label}</span> is <span>less than</span> or <span>equal to {value}</span>",
                },
            },
            numberLessThanOrEqualTo: {
                label: "is less than or equal to",
                summary:
                    "<span>{label}</span> is <span>less than or equal to {value}</span>",
            },
            numberLessThan: {
                label: "is less than",
                summary:
                    "<span>{label}</span> is <span>less than {value}</span>",
            },
            numberPicklist: {
                label: "is",
                summary: "<span>{label}</span> is <span>{value}</span>",
            },
            playbookCompleted: {
                label: "has completed Playbook",
                summary: "Completed Playbook: <span>{playbookName}</span>",
            },
            playbookNotCompleted: {
                label: "has NOT completed Playbook",
                summary: "NOT completed Playbook: <span>{playbookName}</span>",
            },
            playbookCompletedSuccessfully: {
                label: "has SUCCESSFULLY completed Playbook",
                summary:
                    "SUCCESSFULLY completed Playbook: <span>{playbookName}</span>",
            },
            playbookNotCompletedSuccessfully: {
                label: "has not SUCCESSFULLY completed Playbook",
                summary:
                    "Not SUCCESSFULLY completed Playbook: <span>{playbookName}</span>",
            },
            playbookEverIn: {
                label: "is/was in Playbook",
                summary: "Is or Was In Playbook: <span>{playbookName}</span>",
            },
            playbookIn: {
                label: "is in Playbook",
                summary: "In Playbook: <span>{playbookName}</span>",
            },
            playbookNotIn: {
                label: "is NOT in Playbook",
                summary: "NOT in Playbook: <span>{playbookName}</span>",
            },
            playbookNeverIn: {
                label: "has NEVER been in Playbook",
                summary: "NEVER in Playbook: <span>{playbookName}</span>",
            },
            segmentIn: {
                label: "is in Segment",
                summary: "In Segment: <span>{segmentName}</span>",
            },
            segmentNotIn: {
                label: "is NOT in Segment",
                summary: "NOT in Segment: <span>{segmentName}</span>",
            },
            segmentEverIn: {
                label: "has EVER been in Segment",
                summary: "Has EVER Been In Segment: <span>{segmentName}</span>",
            },
            segmentNeverIn: {
                label: "has NEVER been in Segment",
                summary:
                    "Has NEVER Been in Segment: <span>{segmentName}</span>",
            },
            tagIn: {
                label: "is tagged",
                summary: "Tagged: <span>{segmentName}</span>",
            },
            tagNotIn: {
                label: "is NOT tagged",
                summary: "NOT tagged: <span>{segmentName}</span>",
            },
            textBeginsWith: {
                label: "begins with",
                summary:
                    "<span>{label}</span> begins with <span>{value}</span>",
            },
            textBetween: {
                label: "is between",
                summary:
                    "<span>{label}</span> is between <span>{valueLower}</span> and <span>{valueUpper}</span>",
            },
            textContains: {
                label: "contains",
                summary: "<span>{label}</span> contains <span>{value}</span>",
            },
            textNotContains: {
                label: "does not contain",
                summary:
                    "<span>{label}</span> does not contain <span>{value}</span>",
            },
            textEquals: {
                label: "is equal to",
                summary:
                    "<span>{label}</span> is equal to <span>{value}</span>",
            },
            textNotEquals: {
                label: "is not equal to",
                summary:
                    "<span>{label}</span> is not equal to <span>{value}</span>",
            },
            textGreaterThanOrEqualTo: {
                label: "is greater than or equal to",
                summary:
                    "<span>{label}</span> is <span>greater than or equal to {value}</span>",
            },
            textGreaterThan: {
                label: "is greater than",
                summary:
                    "<span>{label}</span> is <span>greater than {value}</span>",
            },
            textLessThanOrEqualTo: {
                label: "is less than or equal to",
                summary:
                    "<span>{label}</span> is <span>less than or equal to {value}</span>",
            },
            textLessThan: {
                label: "is less than",
                summary:
                    "<span>{label}</span> is <span>less than {value}</span>",
            },
        },
    },
    infiniteLoading: {
        account: {
            dashboardsNoMore: "No more dashboards.",
            dashboardsNoResults: "This account has no dashboards.",
            plannerNoMore: "No more items.",
            plannerNoResults: "This account has no open planner items.",
            notesNoMore: "No more notes.",
            notesNoResults: "This account has no notes.",
            filesNoMore: "No more files.",
            filesNoResults: "This account has no files.",
        },
    },
    interactions: {
        created: "created",
        updated: "updated",
        started: "started",
        continued: "continued",
        types: {
            call: {
                label: "Call",
                created: "A new call was logged <em>{title}</em>",
                updated: "A call was updated <em>{title}</em>",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    callerName: "Caller",
                    callerNumber: "Caller Number",
                    contactName: "Contact",
                    contactNumber: "Contact Number",
                    calledAt: "Called At",
                    status: "Status",
                    duration: "Duration",
                },
            },
            conversation: {
                label: "Conversation",
                created: "A new conversation was created",
                updated: "A conversation was updated",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    summary: "Summary",
                },
            },
            deal: {
                label: "Deal",
                created: "A new deal was created <em>{name}</em>",
                updated: "A deal was updated <em>{name}</em>",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    amount: "Amount",
                    probability: "Close Probability",
                    status: "Status",
                    closedAt: "Closed On",
                    currency: "Currency",
                },
            },
            document: {
                label: "Document",
                created: "A new document was created <em>{title}</em>",
                updated: "A document was updated <em>{title}</em>",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                },
            },
            emailActivity: {
                label: "Email",
                created: "Email Sent: {subject}",
                updated: "Email Updated: {subject}",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    openCount: "Open Count",
                    clickCount: "Click Count",
                },
            },
            emailThread: {
                label: "Email Thread",
                created: "Email Sent: {subject}",
                updated: "Email updated: {subject}",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    subject: "Subject",
                    body: "Body",
                    sender: "Sender",
                    recipient: "Recipient",
                    sentAt: "Date Sent",
                },
            },
            event: {
                label: "Event",
                created: "An event was created",
                updated: "An event was updated",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    name: "Name",
                    date: "Date",
                    location: "Location",
                    duration: "Duration",
                },
            },
            featureRequest: {
                label: "Feature Request",
                created: "A feature request was made",
                updated: "A feature request was updated",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    voteCount: "Vote Count",
                    commentCount: "Comment Count",
                },
            },
            invoice: {
                label: "Invoice",
                created: "A new invoice was created",
                updated: "An invoice was updated",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    number: "Number",
                    amount: "Amount",
                    currency: "Currency",
                    dueAt: "Due",
                    status: "Status",
                },
            },
            invoiceItem: {
                label: "Invoice Line Item",
                created: "A new invoice line item was created",
                updated: "An invoice line item was updated",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    name: "Name",
                    description: "Description",
                    amount: "Amount",
                    unitAmount: "Units",
                    quantity: "Quantity",
                },
            },
            note: {
                label: "Note",
                created: "A new note was added <em>{title}</em>",
                updated: "A note was updated <em>{title}</em>",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    body: "",
                },
            },
            npsSurvey: {
                label: "NPS Survey",
                created: "A new NPS Survey <em>{name}</em> was submitted.",
                updated: "A NPS Survey <em>{name}</em> was updated.",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                },
            },
            organization: {
                label: "Organization",
                created: "A new account <em>{name}</em> was added.",
                updated: "An account <em>{name}</em> was updated.",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    name: "Name",
                },
            },
            person: {
                label: "Contact",
                created:
                    "A new contact <em>{firstName} {lastName}</em> was added.",
                updated:
                    "A contact <em>{firstName} {lastName}</em> was updated.",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    emailAddress: "Email",
                    firstName: "First Name",
                    lastName: "Last Name",
                },
            },
            project: {
                label: "Project",
                created: "A new project <em>{name}</em> was added.",
                updated: "A project <em>{name}</em> was updated.",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    name: "Name",
                    description: "Description",
                    startedAt: "Start Date",
                    completedAt: "Completed Date",
                    status: "Status",
                },
            },
            providerInteraction: {
                label: "Provider Interaction",
                created: "A <em>{name}</em> was added.",
                updated: "A <em>{name}</em> was updated.",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                },
            },
            refund: {
                label: "Refund",
                created: "A refund was issued.",
                updated: "A refund was updated.",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    number: "Number",
                    amount: "Amount",
                    status: "Status",
                },
            },
            subscription: {
                label: "Subscription",
                created: "A subscription was created.",
                updated: "A subscription was updated.",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    amount: "Amount",
                    currency: "Currency",
                    startDate: "Start Date",
                    endDate: "End Date",
                    quantity: "Quantity",
                    planInterval: "Plan Interval",
                    status: "Status",
                },
            },
            subscriptionProduct: {
                label: "Subscription Product",
                created: "A subscription product was created.",
                updated: "A subscription product was updated.",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                },
            },
            survey: {
                label: "Survey",
                created: "A survey was received.",
                updated: "A survey was updated.",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    score: "Score",
                    comments: "Comments",
                },
            },
            surveyCes: {
                label: "CES Survey",
                created: "A CES survey was received.",
                updated: "A CES survey was updated.",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    score: "Score",
                    comments: "Comments",
                },
            },
            surveyCsats: {
                label: "CSAT Survey",
                created: "A CSAT survey was received.",
                updated: "A CSATS survey was updated.",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    score: "Score",
                    comments: "Comments",
                },
            },
            surveyNps: {
                label: "NPS Survey",
                created: "An NPS survey was received.",
                updated: "An NPS survey was updated.",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    score: "Score",
                    comment: "Comments",
                },
            },
            task: {
                label: "Task",
                created: "A new task <em>{name}</em> was added.",
                updated: "A task <em>{name}</em> was updated.",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    description: "Description",
                    status: "Status",
                    priority: "Priority",
                    closedAt: "closedAt",
                },
            },
            ticket: {
                label: "Ticket",
                created: "A new ticket <em>{name}</em> was added.",
                updated: "A new ticket <em>{name}</em> was updated.",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    description: "Description",
                    number: "Number",
                    probability: "Probability",
                    priority: "Priority",
                    rating: "Rating",
                    source: "Source",
                    status: "Status",
                    closedAt: "Closed At",
                },
            },
            transaction: {
                label: "Transaction",
                created: "A new transaction was added.",
                updated: "A new transaction was updated.",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    amount: "Amount",
                    currency: "Currency",
                    subject: "Subject",
                },
            },
            transactionalEmail: {
                label: "Transactional Email",
                created: "Email Sent: {subject}",
                updated: "Email Updated: {subject}",
                attributes: {
                    providerInteractionUuid: "Unique Id",
                    openCount: "Open Count",
                    clickCount: "Click Count",
                },
            },
        },
    },
    tasks: {},
    notices: {
        /** GROUP BY VIEW **/
        settings: {
            updated: "Your settings have been updated.",
        },
    },
    tooltips: {
        playbooks: {
            overdue:
                "Playbooks with incomplete tasks remaining after the playbook's estimated completion date.",
            open: "Playbooks with incomplete tasks.",
            completed: "Playbooks with all tasks completed.",
            triggered: "Playbooks activated.",
            succeeded:
                "Playbooks with all tasks completed and an outcome of 'Successful' provided.",
            failed: "Playbooks with all tasks completed and an outcome of 'Failed' provided.",
            noOutcome:
                "Playbooks with all tasks completed and no outcome provided.",
            paused: "Playbooks that are paused and no longer generating new tasks or messages.",
        },
        tasks: {
            open: "Tasks not yet marked 'Complete'.",
            overdue:
                "Tasks not yet marked 'Complete' after the estimated completion date.",
            triggered: "Tasks created by a playbook.",
            completed: "Tasks marked as 'Complete'.",
            skipped: "Tasks marked as 'Skipped'.",
            events: "How many tasks were {status} by day.",
        },
    },
};

export default en;
